import React, { FC, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { context } from '@shared-services/context-service';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { SearchBtn } from './SearchBtn/SearchBtn';
import { SearchPopup } from './SearchPopup/SearchPopup';
import { CollectionsToSearch } from './types';

const unmountTimeout = 500;

export interface RuntimeCollectionSearchProps {
    widgetId: string;
    placeholder?: string;
    _styles?: {
        iconBtn?: ResponsiveStylesProp;
        input?: ResponsiveStylesProp;
        inputFocus?: ResponsiveStylesProp;
        popup?: ResponsiveStylesProp;
        dropdown?: ResponsiveStylesProp;
        dropdownItemHover?: ResponsiveStylesProp;
    };
    collectionsToSearch?: CollectionsToSearch;
}

const RuntimeCollectionSearch: FC<RuntimeCollectionSearchProps> = ({
    widgetId,
    placeholder,
    _styles: styles,
    collectionsToSearch = [
        {
            name: 'catalog_product',
            path: 'product',
        },
    ],
}) => {
    const animatedBlockRef = useRef<HTMLDivElement>(null);
    const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const openSearchPopup = () => {
        if (context.isEditor) {
            return;
        }
        setIsSearchPopupOpen(true);
    };

    const closeSearchPopup = () => {
        setIsSearchPopupOpen(false);
    };

    return (
        <div>
            <SearchBtn styles={styles} openSearchPopup={openSearchPopup} />

            <Transition
                nodeRef={animatedBlockRef}
                in={isSearchPopupOpen}
                timeout={unmountTimeout}
            >
                {(animationState: string) => (
                    <SearchPopup
                        inputRef={inputRef}
                        widgetId={widgetId}
                        placeholder={placeholder}
                        styles={styles}
                        collectionsToSearch={collectionsToSearch}
                        animatedBlockRef={animatedBlockRef}
                        animationState={animationState}
                        closeSearchPopup={closeSearchPopup}
                    />
                )}
            </Transition>
        </div>
    );
};

const BoundedCollectionSearch = withErrorBoundary({
    Comp: RuntimeCollectionSearch,
    componentName: 'RuntimeSearch',
});

const RuntimeCollectionSearchWidget = (props: RuntimeCollectionSearchProps) => (
    <BoundedCollectionSearch {...props} />
);

export default RuntimeCollectionSearchWidget;
